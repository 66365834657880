import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import Error from '@/pages/_error';
import ContentPage from '@/src/cms/components/CMSPage/Content';
import request from '@/utils/axios';

function isNumeric(str) {
  if (typeof str !== 'string') return false;
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
}

const CatchAllPage = props => {
  const router = useRouter();
  const [pageId, setPageId] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [pageData, setPageData] = useState(null);

  const {
    query: { slug = null },
  } = router;

  useEffect(() => {
    if (!slug) return;
    const mounted = async () => {
      const slugParam = slug.join('/');

      if (slug?.length === 1 && isNumeric(slugParam)) {
        setPageId(slugParam);
      } else {
        const response = await request({
          url: `cms/contentpage`,
          params: {
            alias: slugParam,
          },
        });
        const { data } = response;
        if (data?.Success === 'false') {
          setNotFound(true);
        } else {
          setPageId(data.CorePageId);
          setPageData(data);
        }
      }
    };
    mounted();
  }, [slug]);

  if (notFound) return <Error status={404} />;
  if (!pageId) return null;

  return (
    <ContentPage
      {...props}
      {...(pageId ? { sitepageid: pageId } : null)}
      {...(pageData ? { pagedata: pageData } : null)}
      renderOtherType
    />
  );
};

export default CatchAllPage;
